import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navigation from './Navigation'; // Import the Navigation component

const Portfolio = () => {
    const [portfolioData, setPortfolioData] = useState(null); // State to hold portfolio data
    const [isLoading, setIsLoading] = useState(true); // State to track loading status

    // Access Token From Local Storage
    const accessToken = localStorage.getItem('accessToken');
    const authToken = localStorage.getItem('jwtToken');
    const userSId = localStorage.getItem('userSid');

    const handlePortfolio = () => {
        var config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://gw-napi.kotaksecurities.com/Portfolio/1.0/portfolio/v1/holdings?alt=false',
            headers: {
                'accept': '*/*',
                'sid': userSId,
                'Auth': authToken,
                'Authorization': accessToken
            }
        };
        axios(config)
            .then(function (response) {
                setPortfolioData(response.data.data); // Update state with portfolio data
                setIsLoading(false); // Set loading to false after data is fetched
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        handlePortfolio();
    }, []);

    // Function to calculate total profit/loss
    const calculateTotalProfitLoss = () => {
        if (!portfolioData) return 0;
        return portfolioData.reduce((total, item) => {
            return total + ((item.closingPrice - item.averagePrice) * item.quantity);
        }, 0);
    };

    // Function to calculate total investment value
    const calculateTotalInvestmentValue = () => {
        if (!portfolioData) return 0;
        return portfolioData.reduce((total, item) => {
            return total + (item.holdingCost);
        }, 0);
    };

    // Determine color and font weight based on total profit/loss
    const totalProfitLossStyle = {
        color: calculateTotalProfitLoss() >= 0 ? 'green' : 'red',
        fontWeight: 'bold'
    };

    return (
        <div>
            <header className="top-header">
                <Navigation /> {/* Render the Navigation component */}
            </header>
            <div className="container py-4">
                {/* <h3 className="text-success">Portfolio</h3> */}
                
                {/* Display loading alert if loading */}
                {isLoading && <div className="alert alert-info">Loading Portfolio...</div>}
                
                {/* Render table if portfolio data available */}
                {portfolioData && portfolioData.length > 0 && (
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-bordered table-striped my-4">
                                <thead>
                                    <tr>
                                        <th>Equity Name</th>
                                        <th>Buying Price</th>
                                        <th>Current MKT Price</th>
                                        <th>Investment Value</th>
                                        <th>Quantity</th>
                                        <th>Profit/Loss</th> {/* New column for Profit/Loss */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {portfolioData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.symbol}</td>
                                            <td>{(item.averagePrice).toFixed(2)}</td>
                                            <td>{(item.closingPrice).toFixed(2)}</td>
                                            <td>{(item.holdingCost).toFixed(2)}</td>
                                            <td>{item.quantity}</td>
                                            {/* Calculate Profit/Loss and apply appropriate styling */}
                                            <td style={{ color: ((item.closingPrice - item.averagePrice) * item.quantity >= 0) ? 'green' : 'red' }}>
                                                {((item.closingPrice - item.averagePrice) * item.quantity).toFixed(2)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                {/* Table footer for total profit/loss and total investment value */}
                                <tfoot>
                                    <tr>
                                        <th colSpan="3">Total Investment Value:</th>
                                        <td>{calculateTotalInvestmentValue().toFixed(2)}</td>
                                        <th colSpan="1">Total Profit / Loss:</th>
                                        <td style={totalProfitLossStyle}>{calculateTotalProfitLoss().toFixed(2)}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                )}
                
                {/* Display no data alert if portfolioData is empty */}
                {!isLoading && (!portfolioData || portfolioData.length === 0) && (
                    <div className="alert alert-warning">No Investemnet Found in Portfolio.</div>
                )}
            </div>
        </div>
    );
};

export default Portfolio;
