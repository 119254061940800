import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navigation from './Navigation'; // Import the Navigation component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const Positions = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [priceTypes, setPriceTypes] = useState([]); // Array to store price types for each row
    const [priceValues, setPriceValues] = useState([]); // Array to store price values for each row

    // Position Variable
    const [positionData, setPositionData] = useState([]);
    const [sqrofData, setSqrofData] = useState([]);

    // Access Token From Local Storage
    const accessToken = localStorage.getItem('accessToken');
    const authToken = localStorage.getItem('jwtToken');
    const userSId = localStorage.getItem('userSid');
    const serverId = localStorage.getItem('serverId');

    useEffect(() => {
        positionBook()
        const interval = setInterval(() => {
           positionBook();
        }, 1000);

        return () => clearInterval(interval); 
    
      }, []); // Empty dependency array to run this effect only once on component mount


    // Position Book
    const positionBook = () => {

        var config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://gw-napi.kotaksecurities.com/Orders/2.0/quick/user/positions?sId=' + serverId + '',
        headers: {
            'accept': 'application/json',
            'Sid': userSId,
            'Auth': authToken,
            'neo-fin-key': 'neotradeapi',
            'Authorization': accessToken
        }
        };

        axios(config)
        .then(function (response) {
            // console.log(JSON.stringify(response.data));
            const pData = response.data.data;
            // Active Position Check on Market
            const pfilterData = pData.filter(data => (data.flBuyQty !== data.flSellQty));
            const sqrofData = pfilterData.map(position => ({ exSeg : position.exSeg ,qtyToSell: position.flBuyQty - position.flSellQty, TradeSymbol: position.trdSym, symbol: position.sym,strike: position.stkPrc,optionType: position.optTp, avgPrice:position.buyAmt/position.flBuyQty }))
            // console.log('FilterData',pfilterData);
            // console.log("Squere Of Data", sqrofData);
            setSqrofData(sqrofData);
            setPositionData(pData);
            setIsLoading(false);
            // console.log('Position',pData)
        })
        .catch(function (error) {
            console.log(error);
        });
    }



    // Calculate Profit/Loss for each position and determine class
    const calculateProfitLoss = (sellAmount, buyAmount) => {
        const profitLoss = sellAmount - buyAmount;
        const className = profitLoss >= 0 ? "text-success" : "text-danger";
        return { value: profitLoss, className: className };
    }

    const totalSellAmount = positionData && positionData.length > 0 ? positionData.reduce((acc, position) => {
        return acc + parseFloat(position.sellAmt);
    }, 0).toFixed(2) : 0;
    
    const totalBuyAmount = positionData && positionData.length > 0 ? positionData.reduce((acc, position) => {
        return acc + parseFloat(position.buyAmt);
    }, 0).toFixed(2) : 0;
    
    const totalProfitLoss = positionData && positionData.length > 0 ? positionData.reduce((acc, position) => {
        return acc + (position.sellAmt - position.buyAmt);
    }, 0) : 0;
    
    const exchangeSymbolTotals = positionData && positionData.length > 0 ? positionData.reduce((acc, position) => {
        const key = position.exSeg + '-' + position.sym;
        if (!acc[key]) {
            acc[key] = {
                exchange: position.exSeg,
                symbol: position.sym,
                buyAmount: 0,
                sellAmount: 0,
                profitLoss: 0
            };
        }
        acc[key].buyAmount += parseFloat(position.buyAmt);
        acc[key].sellAmount += parseFloat(position.sellAmt);
        acc[key].profitLoss += (parseFloat(position.sellAmt) - parseFloat(position.buyAmt));
        return acc;
    }, {}) : {};
    
    const exchangeSymbolTotalsArray = Object.values(exchangeSymbolTotals);
    

    // Calculate brokerage for each row
    const calculateRowBrokerage = (exchange, buyAmount, sellAmount) => {
        const totalAmount = parseFloat(buyAmount) + parseFloat(sellAmount);
        return calculateBrokerage(exchange, totalAmount);
    };
 
     // Function to calculate brokerage amount based on exchange and total amount
     const calculateBrokerage = (exchange, totalAmount) => {
        if (exchange === 'nse_fo') {
            return (totalAmount / 100000) * 92.46;
        } else if (exchange === 'bse_fo') {
            return (totalAmount / 100000) * 91.55;
        } else if (exchange === 'mcx_fo') {
            return (totalAmount / 100000) * 60.62;
        } else if (exchange === 'nse_cm') {
            return (totalAmount / 100000) * 18.07;
        }
        return 0;
    };

    const totalBrokerage = exchangeSymbolTotalsArray && exchangeSymbolTotalsArray.length > 0 ? exchangeSymbolTotalsArray.reduce((acc, total) => {
        return acc + calculateRowBrokerage(total.exchange, total.buyAmount, total.sellAmount);
    }, 0).toFixed(2) : 0;

    // Calculate net profit/loss after brokerage deduction for each row
    const calculateNetProfitLoss = (exchange, buyAmount, sellAmount, profitLoss) => {
    const brokerage = calculateRowBrokerage(exchange, buyAmount, sellAmount);
    return profitLoss - brokerage;
    };
    
    const totalNetProfitLoss = exchangeSymbolTotalsArray && exchangeSymbolTotalsArray.length > 0 ? exchangeSymbolTotalsArray.reduce((acc, total) => {
        return acc + calculateNetProfitLoss(total.exchange, total.buyAmount, total.sellAmount, total.profitLoss);
    }, 0).toFixed(2) : 0;

    // Close Position Logic 

     // Placing Order

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  

  const handleAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);

    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

 const closePosition = (index) => {

    const sqrPosition = sqrofData[index];

    // console.log("Close Position",sqrofData[index]);
    console.log ("Avg Price",sqrPosition.avgPrice);

    const jData = {
        "am": "NO",
        "dq": "0",
        "pc": "NRML",
        "rt": "DAY",
        "pf": "N",
        "mp": "0",
        "pt": priceTypes[index] || "MKT",
        "es": sqrPosition.exSeg,
        "pr": priceValues[index] || "0",
        "qt": sqrPosition.qtyToSell.toString(),
        "ts": sqrPosition.TradeSymbol,
        "tt": "S"
        }

        console.log("JData",jData);

        var qs = require('qs');
        var data = qs.stringify({
        'jData': JSON.stringify(jData)
        });

        var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://gw-napi.kotaksecurities.com/Orders/2.0/quick/order/rule/ms/place?sId=' + serverId + '',
        headers: {
            'accept': 'application/json',
            'Sid': userSId,
            'Auth': authToken,
            'neo-fin-key': 'neotradeapi',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': accessToken,
        },
        data: data
        };

        axios(config)
        .then(function (response) {
            handleAlert('success', 'Position Close Successfully.');
            positionBook();
        })
        .catch(function (error) {
            console.log(error);
            handleAlert('danger', 'Failed to Position Close.');
        });
    }

    // Function to handle price type change for a specific row
    const handlePriceTypeChange = (index, type) => {
        const newPriceTypes = [...priceTypes]; // Copy existing priceTypes array
        newPriceTypes[index] = type; // Update value at specific index
        setPriceTypes(newPriceTypes); // Update priceTypes state with modified array
    };

    // Function to handle price value change for a specific row
    const handlePriceValueChange = (index, value) => {
        const newPriceValues = [...priceValues]; // Copy existing priceValues array
        newPriceValues[index] = value; // Update value at specific index
        setPriceValues(newPriceValues); // Update priceValues state with modified array
    };

    // Adding Data on Google Sheet 
    const readGoogleSheet = () => {
        fetch('https://sheetdb.io/api/v1/t18q7h8nlp836')
        .then((response) => response.json())
        .then((data) => console.log(data));
        }
        
    const addDataGoogleSheet = () => {
        const currentDate = moment().format('YYYY-MM-DD');
    
        fetch('https://sheetdb.io/api/v1/t18q7h8nlp836')
        .then((response) => response.json())
        .then((data) => {
        // Check if any entry has today's date
        const todayData = data.find(entry => moment(entry.Date).format('YYYY-MM-DD') === currentDate);
        console.log(todayData);
        
        if (todayData) {
            console.log('Data for today exists:', todayData);
        } else {
            console.log('No data found for today.');
            fetch('https://sheetdb.io/api/v1/t18q7h8nlp836', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: [
                {
                    'Date': currentDate,
                    'PNL': totalNetProfitLoss,
                }
            ]
        })
        })
        .then((response) => response.json())
        .then((data) => console.log(data));
        }
        })
        .catch((error) => {
        console.error('Error:', error);
        }); 
    }

    // useEffect(() => {
    // // One Time Run Function
    // addDataGoogleSheet();
    // },[])

    return (
        <div>
            <header className="top-header">
                <Navigation /> {/* Render the Navigation component */}
            </header>
            <div className='container py-5'>
                {/* Position table */}
                <div className='row'>
                    <div className='col-12 pb-3'>
                        {/* <button className='btn btn-primary mb-4' onClick={closePosition}>Check Position</button> */}
                        {showAlert && (
                        <div className={`alert alert-${alertType}`} role="alert">
                            {alertMessage}
                        </div>
                        )} 
                        {sqrofData && sqrofData.length > 0 ? (
                        <div className='table-responsive'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr className='text-center'>
                                        <th>Symbol</th>
                                        <th>Strike Price</th>
                                        <th>Avg Price</th>
                                        <th>Quantity to Sell</th>
                                        <th>MKT/Limit</th>
                                        <th>Price</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sqrofData.map((position, index) => (
                                        <tr key={index} className="text-center">
                                            <td>{position.symbol}</td>
                                            <td>{parseInt(position.strike)}{position.optionType}</td>
                                            <td>{(position.avgPrice).toFixed(2)}</td>
                                            <td>{position.qtyToSell}</td>
                                            <td>
                                                <select
                                                    className="form-select"
                                                    value={priceTypes[index] || ''} // Bind to priceTypes array with index
                                                    onChange={(e) => handlePriceTypeChange(index, e.target.value)} // Pass index to handlePriceTypeChange
                                                >
                                                    <option value="MKT">MKT</option>
                                                    <option value="L">L</option>
                                                </select>
                                            </td>
                                            <td>
                                                {priceTypes[index] === 'L' ? (
                                                    <input
                                                        type="text"
                                                        className="form-control sqr-prc"
                                                        placeholder="Price"
                                                        value={priceValues[index] || ''} // Bind to priceValues array with index
                                                        onChange={(e) => handlePriceValueChange(index, e.target.value)} // Pass index to handlePriceValueChange
                                                    />
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </td>

                                            <td>
                                                    <button className="btn btn-danger btn-sm me-1" value={index} onClick={() => closePosition(index)}>
                                                        <FontAwesomeIcon icon={faPowerOff} />
                                                    </button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="alert alert-info">No Squereoff data available</div>
                    )}
                    </div>
                    <div className='col-12 py-3'>
                    {isLoading ? (
                            <p className='alert alert-warning'>No Positions Data Found.</p>
                        ) : (
                            <>
                                {positionData && positionData.length > 0 ? (
                                    <div className='table-responsive'>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className='text-center'>
                                                <th>No</th>
                                                <th>Symbol</th>
                                                <th>Strike Price</th>
                                                <th>Buy Amount</th>
                                                <th>Sell Amount</th>
                                                <th>Quantity</th>
                                                <th>Profit/Loss</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {positionData.map((position, index) => {
                                                const { value, className } = calculateProfitLoss(position.sellAmt, position.buyAmt);
                                                return (
                                                    <tr key={index} className="text-center">
                                                        <td>{index + 1}</td>
                                                        <td>{position.sym}</td>
                                                        <td>{parseInt(position.stkPrc)} {position.optTp}</td>
                                                        <td>{position.buyAmt}</td>
                                                        <td>{position.sellAmt}</td>
                                                        <td>{position.flBuyQty}</td>
                                                        <td className={className}>{value}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr className='text-center'>
                                                <th colSpan="6" className="">Total Profit / Loss</th>
                                                <th className={totalProfitLoss >= 0 ? 'text-success' : 'text-danger'}>{totalProfitLoss}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    </div>
                                ) : (
                                    <div className="alert alert-info">No positions available</div>
                                )}
                            </>
                        )}
                    </div>
                    <div className='col-12 py-3'>
                        {isLoading ? (
                            <p className='alert alert-warning'>No Brokerage Calculated.</p>
                        ) : (
                            <>
                                {exchangeSymbolTotalsArray && exchangeSymbolTotalsArray.length > 0 ? (
                                    <div className='table-responsive'>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className='text-center'>
                                                <th>Exchange</th>
                                                <th>Symbol</th>
                                                <th>Total Buy Amount</th>
                                                <th>Total Sell Amount</th>
                                                <th>Total Profit/Loss</th>
                                                <th>Brokerage</th>
                                                <th>Net Profit/Loss</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {exchangeSymbolTotalsArray.map((total, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{total.exchange}</td>
                                                    <td>{total.symbol}</td>
                                                    <td>{total.buyAmount.toFixed(2)}</td>
                                                    <td>{total.sellAmount.toFixed(2)}</td>
                                                    <td className={total.profitLoss >= 0 ? 'text-success' : 'text-danger'}>{total.profitLoss.toFixed(2)}</td>
                                                    <td>{calculateRowBrokerage(total.exchange, total.buyAmount, total.sellAmount).toFixed(2)}</td>
                                                    <td>{calculateNetProfitLoss(total.exchange, total.buyAmount, total.sellAmount, total.profitLoss).toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr className='text-center'>
                                                <th colSpan="6" className="">Total Net Profit/Loss</th>
                                                <th className={totalNetProfitLoss >= 0 ? 'text-success' : 'text-danger'}>{totalNetProfitLoss}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div className='text-end'>
                                    <button className='btn btn-success' onClick={()=>addDataGoogleSheet()}>Add PNL GoogleSheet</button>
                                    </div>
                                    </div>
                                ) : (
                                    <div className="alert alert-info">No brokerage data available</div>
                                )}
                            </>
                        )}
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default Positions;
