import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faKey, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode"
import Dashboard from './Dashboard';

const LoginForm = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // New state to toggle password visibility
    const [showOTPField, setShowOTPField] = useState(false);
    const [otp, setOTP] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false); // New state to track login status

    const navigate = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');
        const expirationTime = localStorage.getItem('expireToken');

        if (storedToken && expirationTime) {
            const currentTime = new Date().getTime();
            if (currentTime < expirationTime) {
                // Token is still valid, redirect to dashboard
                setIsLoggedIn(true); // Set login status to true
            }
        }
        
    }, []);

    useEffect(() => {
      // Check if access token exists in local storage
      const accessToken = localStorage.getItem('accessToken');
  
      if (!accessToken) {
        // Redirect to accessToken page
        navigate("/");
      }

      // Check if set both then redirect to dashboard
      const SID = localStorage.getItem('userSid');
      const jwtToken = localStorage.getItem('jwtToken');

      // Check if SID and jwtToken are found then its redirect to dashboard
      if (SID || jwtToken) {
        // Redirect to the login page
        navigate('/dashboard'); // Navigate to the login page
      }

    }, [navigate]);
  
    const handleUsernameChange = (e) => {
      setUsername(e.target.value);
    };
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };

    const handleOTPChange = (e) => {
        setOTP(e.target.value);
      };
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
        };

  
    const handleSubmit = (e) => {
      e.preventDefault();

      // Access Token From Local Storage Get
      const storedToken = localStorage.getItem('accessToken');
      const tokenType = localStorage.getItem('tokenType');
      if(storedToken){
        var accessToken = tokenType +" "+ storedToken ;
        localStorage.setItem('accessToken', accessToken);
         // console.log(accessToken);
      }

      // Login Step-1 Authentication logic
        // var data = JSON.stringify({
        // "mobileNumber": "+91"+username,
        // "password": password
        // });

        // var config = {
        // method: 'post',
        // maxBodyLength: Infinity,
        // url: 'https://gw-napi.kotaksecurities.com/login/1.0/login/v2/validate',
        // headers: { 
        //     'accept': '*/*', 
        //     'Content-Type': 'application/json', 
        //     'Authorization': accessToken,
        // },
        // data : data
        // };

        const data = {
          username,
          password,
          accessToken
        };

        axios.post('http://localhost:3001/proxy/login/validate', data).then(function (response) {
            // Store data on loignData object
            const loginData = JSON.parse(JSON.stringify(response.data));

            // console.log('LoginData',loginData);

            if(loginData){
                const userSid = loginData.data.sid;
                const jwtToken = loginData.data.token;
                const serverId = loginData.data.hsServerId;
                const userName = loginData.data.greetingName;
                const decoded = jwtDecode(jwtToken); // Decode JWTToken for get sID
                const userId = decoded.sub;  // Use For Authentication User

                // Store Data in Local Storage 
                localStorage.setItem('jwtSub', userId);
                localStorage.setItem('serverId', serverId);
                localStorage.setItem('jwtToken', jwtToken);
                localStorage.setItem('userSid', userSid);
                localStorage.setItem('userName', userName);

                // Get OTP from Login Data 
                var otpData = JSON.stringify({
                    "userId": userId,
                    "sendEmail": true,
                    "isWhitelisted": true
                  });
                  
                  var config = {
                    method: 'post',
                  maxBodyLength: Infinity,
                    url: 'https://gw-napi.kotaksecurities.com/login/1.0/login/otp/generate',
                    headers: { 
                      'accept': '*/*', 
                      'Content-Type': 'application/json', 
                      'Authorization': accessToken,
                    },
                    data : otpData
                  };
                  
                  axios(config).then(function (response) {

                    setShowOTPField(true);
                    
                  }).catch(function (error) {
                    console.log(error);
                  });
      
                  } else {
                      // Handle other cases, e.g., display error message
                  }
                 
                  }) .catch(function (error) {
                    console.log(error);
              });
      
    }

    const handleOTPSubmit = (e) => {
        e.preventDefault();

        // Access Token From Local Storage
        const accessToken = localStorage.getItem('accessToken');
        const authToken = localStorage.getItem('jwtToken');
        const userId = localStorage.getItem('jwtSub');
        const userSId = localStorage.getItem('userSid');

        var data = JSON.stringify({
          "userId": userId,
          "otp": otp,
        });

        var config = {
          method: 'post',
        maxBodyLength: Infinity,
          url: 'https://gw-napi.kotaksecurities.com/login/1.0/login/v2/validate',
          headers: { 
            'accept': '*/*', 
            'sid': userSId, 
            'Auth': authToken,
            'Content-Type': 'application/json', 
            'Authorization': accessToken,
          },
          data : data
        };

        axios(config)
        .then(function (response) {
          
            setIsLoggedIn(true); // Set login status to true
            navigate('/dashboard', {
              state: {
                  responseData: response.data
              },
            });
            // const otpData = console.log(JSON.stringify(response.data));
            // console.log('otpData',otpData)
          
        })
        .catch(function (error) {
          console.log(error);
        });

      }

      if (isLoggedIn) {
        return <Dashboard />;
      }

  return (
    <div className='centered-form'>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="card">
                      <div className="card-header text-center">
                          <h4>Welcome to Kotak Neo Admin</h4>
                      </div>
                      <div className="card-body">
                      {isLoggedIn ? (
                            <div>
                                <p>You are already logged in.</p>
                                <button className="btn btn-primary" onClick={() => navigate('/dashboard')}>Go to Dashboard</button>
                            </div>
                        ) : (
                      <form onSubmit={showOTPField ? handleOTPSubmit : handleSubmit}>
                            <div className="mb-3">
                                <label for="username" className="form-label">Username</label>
                                <div className="input-group">
                                <span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
                                <input type="text" className="form-control" name="username" placeholder="Enter your username" value={username} onChange={handleUsernameChange} />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <div className="input-group">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faLock} /></span>
                                    <input type={showPassword ? "text" : "password"} className="form-control" name="password" placeholder="Enter your password" value={password} onChange={handlePasswordChange} disabled={showOTPField} />
                                    <button type="button" className="btn btn-outline-secondary" onClick={togglePasswordVisibility}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </div>
                            {showOTPField && (
                              <div className="mb-3">
                                  <label htmlFor="otp" className="form-label">OTP</label>
                                  <div className="input-group">
                                  <span class="input-group-text"><FontAwesomeIcon icon={faKey} /></span>
                                  <input type="text" className="form-control" name="otp" placeholder="Enter OTP" value={otp} onChange={handleOTPChange}/>
                                  </div>
                              </div>
                            )}
                            <div class="d-grid">
                            <button type="submit" className="btn btn-success">{showOTPField ? "Submit OTP" : "Login"}</button>
                            </div>
                          </form>
                           )}
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
  )
}


export default LoginForm;
