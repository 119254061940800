import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Login';

const AccessTokenForm = () => {
    const navigate = useNavigate(); // Initialize useNavigate
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || '');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
      // Check if access token exists in local storage
      const storedToken = localStorage.getItem('accessToken');
      if (storedToken) {
        setLoggedIn(true);
      }
    }, []);

    const handleUsernameChange = (e) => {
      setUsername(e.target.value);
    };
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
    
      const data = {
        username,
        password,
      };
    
      axios.post('http://localhost:3001/proxy/token', data)
        .then(function (response) {
          const token = response.data.access_token;
          const tokenExpire = response.data.expires_in;
          const tokenType = response.data.token_type;
          if (token) {
            setAccessToken(token);
            localStorage.setItem('accessToken', token);
            localStorage.setItem('expireToken', tokenExpire);
            localStorage.setItem('tokenType', tokenType);
            setLoggedIn(true);
            navigate('/login');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    if (loggedIn) {
      return <Login />;
    }

  return (
   
    <div>
      <div class='centered-form'>
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-md-5">

                  <div className="card">
                      <div className="card-header text-center">
                          <h4>Kotak Neo API</h4>
                      </div>
                      <div className="card-body">
                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label for="username" className="form-label">API Username</label>
                                <div className="input-group">
                                <span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
                                <input type="text" className="form-control" name="username" placeholder="Enter your username" value={username} onChange={handleUsernameChange} />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label for="password" className="form-label">API Password</label>
                                <div className="input-group">
                                <span className="input-group-text"><FontAwesomeIcon icon={faLock} /></span>
                                <input type="text" className="form-control" name="password" placeholder="Enter your password" value={password} onChange={handlePasswordChange}/>
                                </div>
                            </div>
                            <div class="d-grid">
                                <button type="submit" className="btn btn-primary">Set AccessToken</button>
                            </div>
                          </form>
                      </div>
                    </div>
    
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
};

export default AccessTokenForm;
