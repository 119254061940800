import React, { useState, useEffect } from 'react';
import Navigation from './Navigation'; // Import the Navigation component

function InvestmentPage() {
  const [amount, setAmount] = useState('');
  const [percentage, setPercentage] = useState('');
  const [recurringTimes, setRecurringTimes] = useState('');
  const [calculationRows, setCalculationRows] = useState([]);
  const [totalPercentageReturn, setTotalPercentageReturn] = useState(0);

  // User For order Staking
  const [entryPrice, setEntryPrice] = useState('');
  const [priceDifference, setPriceDifference] = useState('');
  const [quantity, setQuantity] = useState('');
  const [stack, setStack] = useState('');
  const [exitPrice, setExitPrice] = useState('');
  const [results, setResults] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalLast, setTotalLast] = useState(0);
  const [showTable, setShowTable] = useState(false); // State variable to manage table visibility

  // Json Data Generator
  const [inputValue, setInputValue] = useState('');
  const [inputGap, setInputGap] = useState('');
  const [numValues, setNumValues] = useState('');
  const [jsonData, setJsonData] = useState('');

  useEffect(() => {
    // Set default exit price to the second last entry price
    const entryPriceInputs = document.querySelectorAll('#resultsTableBody td:first-child');
    if (entryPriceInputs.length >= 2) {
      const secondLastEntryPrice = entryPriceInputs[entryPriceInputs.length - 2].textContent;
      setExitPrice(secondLastEntryPrice);
    }
  }, []);

  const calculate = () => {
    let startingAmount = parseFloat(amount);
    const percentageValue = parseFloat(percentage);
    const times = parseInt(recurringTimes);
    let totalReturn = 0;
    const rows = [];

    for (let i = 0; i < times; i++) {
      const calculatedValue = startingAmount + (startingAmount * percentageValue / 100);
      const percentageReturn = calculatedValue - startingAmount;

      totalReturn += percentageReturn;

      rows.push({
        amount: startingAmount.toFixed(2),
        percentage: percentageValue.toFixed(2),
        times: i + 1,
        calculatedValue: calculatedValue.toFixed(2),
        percentageReturn: percentageReturn.toFixed(2)
      });

      startingAmount = calculatedValue;
    }

    setCalculationRows(rows);
    setTotalPercentageReturn(totalReturn.toFixed(2));
  };

  // Order Staking Logic

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowTable(true);

    // Initialize quantityMultiplier
    let quantityMultiplier = 1;
    let lotSize = 1;

    // Initialize totals
    let totalQuantity = 0;
    let totalPrice = 0;
    let totalLast = 0;

    // Calculate and set results
    const calculatedResults = [];
    for (let i = 0; i < stack; i++) {
      const reducedPrice = entryPrice - priceDifference * i;
      const totalPriceStack = reducedPrice * quantity * quantityMultiplier;
      const lastColumnValue = (exitPrice * lotSize) - (reducedPrice * lotSize);
      calculatedResults.push({
        reducedPrice: reducedPrice.toFixed(2),
        lotSize,
        quantity: quantity * quantityMultiplier,
        totalPriceStack: totalPriceStack.toFixed(2),
        lastColumnValue: lastColumnValue.toFixed(2)
      });

      // Update totals
      totalQuantity += quantity * quantityMultiplier;
      totalPrice += totalPriceStack;
      totalLast += lastColumnValue;

      // Increase quantityMultiplier and lotSize
      quantityMultiplier *= 2;
      lotSize *= 2;
    }

    // Set results and totals
    setResults(calculatedResults);
    setTotalQuantity(totalQuantity);
    setTotalPrice(totalPrice);
    setTotalLast(totalLast);

    // Calculate and display additional fields (result and percentage)
    const result = quantity * totalLast;
    const alertField = document.querySelectorAll('.alert-field');
    alertField[0].textContent = "Result : " + result.toFixed(2);
    alertField[0].className = 'alert-field mt-3 alert font-weight-bold ' + (result >= 0 ? 'alert-success' : 'alert-danger');

    const percentage = result * 100 / totalPrice;
    alertField[1].textContent = "Percentage : " + percentage.toFixed(2) + "%";
    alertField[1].className = 'alert-field mt-3 alert font-weight-bold ' + (percentage >= 0 ? 'alert-success' : 'alert-danger');
  };

  // Json Data Generator

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    const parsedInputValue = parseInt(inputValue);
    const parsedInputGap = parseInt(inputGap);
    const parsedNumValues = parseInt(numValues);

    if (isNaN(parsedInputValue) || isNaN(parsedInputGap) || isNaN(parsedNumValues)) {
      alert('Please enter valid numbers.');
      return;
    }
    const generatedData = generateJSON(parsedInputValue, parsedInputGap, parsedNumValues);
    setJsonData(JSON.stringify(generatedData));
  };

  const generateJSON = (value, gap, numValues) => {
    const generatedData = [];

    for (let i = value - (numValues * gap); i <= value + (numValues * gap); i += gap) {
      generatedData.push(i);
    }

    return generatedData;
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(jsonData)
      .then(() => {
        console.log('JSON data copied to clipboard:', jsonData);
        alert('JSON data copied to clipboard!');
      })
      .catch(err => {
        console.error('Error copying to clipboard:', err);
      });
  };


  return (
    <div>
        <header className="top-header">
            <Navigation /> {/* Render the Navigation component */}
        </header>

        {/* Order Staking */}
        <div className="container py-4">
        <h3 className="mb-4 text-success">Order Stacking</h3>
        <div className="row">
            <div className="col-md-4">
            <form id ='orderForm' onSubmit={handleSubmit}>
                <div className="form-group">
                <label htmlFor="entryPrice">Entry Price :</label>
                <input type="number" placeholder='Entery Price' className="form-control" id="entryPrice" value={entryPrice} onChange={(e) => setEntryPrice(e.target.value)} required />
                </div>
                <div className="form-group">
                <label htmlFor="priceDifference">Price Difference :</label>
                <input type="number" placeholder='Price Difference' className="form-control" id="priceDifference" step="0.01" value={priceDifference} onChange={(e) => setPriceDifference(e.target.value)} required />
                </div>
                <div className="form-group">
                <label htmlFor="quantity">Quantity :</label>
                <input type="number" placeholder='Quantity' className="form-control" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
                </div>
                <div className="form-group">
                <label htmlFor="stack">Stack :</label>
                <input type="number" placeholder='Number of Stack' className="form-control" id="stack" value={stack} onChange={(e) => setStack(e.target.value)} required />
                </div>
                <div className="form-group">
                <label htmlFor="exitPrice">Exit Price :</label>
                <input type="number" placeholder='Exit Price' className="form-control" id="exitPrice" step="0.01" value={exitPrice} onChange={(e) => setExitPrice(e.target.value)} />
                </div>
                <button type="submit" className="btn btn-primary">Calculate</button>
            </form>
            </div>
            <div className="col-md-8">
                <div className="result">
                    <h6 className="alert-field"></h6>
                    <h6 className="alert-field mt-3"></h6>
                    {showTable && ( // Render the table only if showTable is true
                    <div class="table-responsive">
                    <table className="table text-center table-bordered">
                    <thead>
                        <tr>
                        <th>Price</th>
                        <th>Shares</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Last</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((result, index) => (
                        <tr key={index}>
                            <td>{result.reducedPrice}</td>
                            <td>{result.lotSize}</td>
                            <td>{result.quantity}</td>
                            <td>{result.totalPriceStack}</td>
                            <td>{result.lastColumnValue}</td>
                        </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                        <th colSpan="2">Total</th>
                        <th>{totalQuantity}</th>
                        <th>{totalPrice}</th>
                        <th>{totalLast}</th>
                        </tr>
                    </tfoot>
                    </table>
                    </div>
                    )}
                </div>
            
            </div>
        </div>
        </div>
        <hr></hr>
        {/* Investment */}
        <div className="container py-4">
            <h3 className="mb-4 text-success">Investment Calculator</h3>
            <div className="row">
                <div className="col-md-4">
                <div className="form-group">
                    <label htmlFor="amount">Amount:</label>
                    <input type="number" className="form-control" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <label htmlFor="percentage">Percentage:</label>
                    <input type="number" className="form-control" id="percentage" value={percentage} onChange={(e) => setPercentage(e.target.value)} />
                </div>
                </div>
                <div className="col-md-4">
                <div className="form-group">
                    <label htmlFor="recurringTimes">Recurring Times:</label>
                    <input type="number" className="form-control" id="recurringTimes" value={recurringTimes} onChange={(e) => setRecurringTimes(e.target.value)} />
                </div>
                </div>
            </div>
            <button className="btn btn-primary mt-3" onClick={calculate}>Calculate</button>
            <hr />
            <div class="table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <th>Amount</th>
                    <th>Percentage</th>
                    <th>Recurring Times</th>
                    <th>Calculated Value</th>
                    <th>Percentage Return</th>
                </tr>
                </thead>
                <tbody>
                {calculationRows.map((row, index) => (
                    <tr key={index}>
                    <td>{row.amount}</td>
                    <td>{row.percentage}</td>
                    <td>{row.times}</td>
                    <td>{row.calculatedValue}</td>
                    <td>{row.percentageReturn}</td>
                    </tr>
                ))}
                </tbody>
                <tfoot>
                <tr>
                    <th colSpan="4">Total Percentage Return</th>
                    <td>{totalPercentageReturn}</td>
                </tr>
                </tfoot>
            </table>
            </div>
        </div>
        <hr></hr>
        {/* JSON Data Generator */}
        <div className="container py-4 mb-5">
          <h3 className="mb-4 text-success">JSON Data Generation</h3>
            <div className="row">
              <div className="col-md-6">
                <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label htmlFor="inputValue" className="form-label">Enter Strike Price</label>
                <input type="number" className="form-control" id="inputValue" placeholder="Strike Price Value" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="inputGap" className="form-label">Gap Value:</label>
                <input type="number" className="form-control" id="inputGap" placeholder="Enter the gap value" value={inputGap} onChange={(e) => setInputGap(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="numValues" className="form-label">Strike Price Range </label>
                <input type="number" className="form-control" id="numValues" placeholder="Enter the number of values up and down" value={numValues} onChange={(e) => setNumValues(e.target.value)} />
              </div>
              <div className=''>
                <button type="submit" className="btn btn-primary me-4 mb-2">Generate JSON</button>
                <button className="btn btn-secondary" onClick={handleCopyToClipboard}>Copy JSON Data</button>
              </div>
                </form>
                <div class="table-responsive">
                <div className="mt-3" id="jsonDataDisplay">{jsonData}</div>
                </div>
              </div>
            </div>
        </div>  
    </div>
   
  );
}

export default InvestmentPage;
