import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Navigation from './Navigation'; // Import the Navigation component
import { Pagination, Modal, Button, Form  } from 'react-bootstrap';
// import Footer from './Footer';

const Orders = () => {

  // Access Data From Local Storage
  const accessToken = localStorage.getItem('accessToken');
  const authToken = localStorage.getItem('jwtToken');
  const userSId = localStorage.getItem('userSid');
  const serverId = localStorage.getItem('serverId');
  
  const orderSt = "open"; // after market order req received // open // rejected
  const [amo, setAmo] = useState("NO");
  const [formDataArray, setFormDataArray] = useState([]);
  const [symbolData, setSymbolData] = useState({
    "nse_fo": {
      "NIFTY": {
        "strikePrices": [22700,22750,22800,22850,22900,22950,23000,23050,23100,23150,23200,23250,23300,23350,23400,23450,23500,23550,23600,23650,23700,23750,23800,23850,23900],
        "expiryDates": ["24-6-13"],
        "defaultQuantity": 25
      },
      // "BANKNIFTY": {
      //   "strikePrices": [48700,48800,48900,49000,49100,49200,49300,49400,49500,49600,49700,49800,49900,50000,50100,50200,50300,50400,50500,50600,50700,50800,50900,51000],
      //   "expiryDates": ["24-6-12"],
      //   "defaultQuantity": 15
      // },
      // "FINNIFTY": {
      //   "strikePrices": [21400,21450,21500,21550,21600,21650,21700,21750,21800,21850,21900,21950,22000,22050,22100,22150,22200,22250,22300,22350,22400,22450,22500,22550,22600,22650,22700,22750,22800,22850,22900],
      //   "expiryDates": ["24-6-11"],
      //   "defaultQuantity": 40
      // },
      // "MIDCPNIFTY": {
      //   "strikePrices": [10600,10625,10650,10675,10700,10725,10750,10775,10800,10825,10850,10875,10900,10925,10950,10975,11000,11025,11050,11075,11100,11125,11150,11175,11200,11225,11250],
      //   "expiryDates": ["24-5-17"],
      //   "defaultQuantity": 75
      // }  
      
    },
    "bse_fo": {
      "SENSEX": {
        "strikePrices": [75000,76000,77000,78000],
        "expiryDates": ["24-6-14"],
        "defaultQuantity": 10
      },
      // "BANKEX": {
      //   "strikePrices": [51300,51400,51500,51600,51700,51800,51900,52000,52100,52200,52300,52400,52500,52600,52700,52800,52900,53000,53100,53200,53300,53400,53500,53600,53700,53800,53900,54000,54100,54200,54300],
      //   "expiryDates": ["24-APR"],
      //   "defaultQuantity": 15
      // }
    }
  });

  
  const [exchangeSegment, setExchangeSegment] = useState("nse_fo");
  const [selectedSymbol, setSelectedSymbol] = useState(""); // Initialize selectedSymbol
  const [orderType, setOrderType] = useState("");
  const [optionType, setOptionType] = useState("");
  const [selectedStrikePrice, setSelectedStrikePrice] = useState("");
  const [selectedExpiryDate, setSelectedExpiryDate] = useState("");
  const [defaultQuantity, setDefaultQuantity] = useState(0);
  const [price, setPrice] = useState("");
  const [priceDifference, setPriceDifference] = useState(0);
  const [orderStack, setOrderStack] = useState(0);
  const [tsString, setTsString] = useState("");
  const [buySell, setBuySell] = useState("B");


  useEffect(() => {
    populateSymbols();
  }, [exchangeSegment]);

  const populateSymbols = () => {
    setSelectedSymbol(Object.keys(symbolData[exchangeSegment])[0]);
  };

  useEffect(() => {
    if (selectedSymbol && symbolData[exchangeSegment][selectedSymbol]) {
      setDefaultQuantity(symbolData[exchangeSegment][selectedSymbol].defaultQuantity);
    }
  }, [selectedSymbol, exchangeSegment, symbolData]);

  useEffect(() => {
    const defaultOrderType = "L";
    const defaultOptionType = "PE";
    setOrderType(defaultOrderType);
    setOptionType(defaultOptionType);

    // Run when Page Initialize
    orderBook();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      orderBook(); // Call orderBook function every 1 second
    }, 1000 );

    return () => clearInterval(interval); // Cleanup function to clear interval on unmount

  }, []); // Empty dependency array to run this effect only once on component mount

  // tsString Section 

  useEffect(() => {
    // Set default expiry date and strike price if not already set
    if (selectedSymbol && symbolData[exchangeSegment][selectedSymbol]) {
        const defaultExpiryDate = symbolData[exchangeSegment][selectedSymbol].expiryDates[0];
        const defaultStrikePrice = symbolData[exchangeSegment][selectedSymbol].strikePrices[0];
        setSelectedExpiryDate(defaultExpiryDate);
        setSelectedStrikePrice(defaultStrikePrice);
      //   console.log("Default Expiry Date:", defaultExpiryDate);
    
    }
}, [selectedSymbol, exchangeSegment, symbolData]);

  useEffect(() => {
    const expiryDateUpdate = selectedExpiryDate.split('-').join('');
    const tsStringValue = `${selectedSymbol}${expiryDateUpdate}${selectedStrikePrice}${optionType}`;
    setTsString(tsStringValue);
    // console.log("tsStringValue Final:", tsStringValue);
  }, [selectedSymbol, selectedExpiryDate, selectedStrikePrice, optionType]);


  const togglePriceFieldVisibility = () => {
    return orderType === "MKT" ? "none" : "block";
  };

  useEffect(() => {
    generateFormData();
  }, [selectedSymbol, selectedExpiryDate,orderType, selectedStrikePrice, optionType, tsString, buySell, orderStack, price, priceDifference]);

  
  const generateFormData = () => {
    const orderTypeValue = orderType;
    const defaultPrice = parseFloat(price);
    const orderSize = parseFloat(orderStack);
    const buySellValue = buySell;
    let quantity = parseInt(defaultQuantity);

    const priceDifferenceValue = parseFloat(priceDifference);
    const formDataArray = [];

    let freezeQuantity = 0;
    switch(selectedSymbol) {
      case "NIFTY":
        freezeQuantity = 1800;
        break;
      case "BANKNIFTY":
        freezeQuantity = 900;
        break;
      case "FINNIFTY":
        freezeQuantity = 1800;
        break;
      case "MIDCPNIFTY":
        freezeQuantity = 4200;
        break;
      case "SENSEX":
        freezeQuantity = 1000;
        break;
      case "BANKEX":
        freezeQuantity = 900;
        break;
      default:
        freezeQuantity = 0;
    }

    for (let i = 0; i < orderSize; i++) {
      const price = defaultPrice - i * priceDifferenceValue;
      if (!isNaN(price) && !isNaN(quantity)) {
        const nextQuantity = i < orderSize - 1 ? quantity * 2 : quantity;

        while(quantity > freezeQuantity) {
          formDataArray.push({
            "am": amo,
            "dq": "0",
            "pc": "NRML",
            "rt": "DAY",
            "pf": "N",
            "mp": "0",
            "pt": orderTypeValue,
            "es": exchangeSegment,
            "pr": price.toFixed(2),
            "qt": freezeQuantity,
            "ts": tsString,
            "tt": buySellValue
          });
          quantity -= freezeQuantity;
        }

        if(quantity > 0) {
          formDataArray.push({
            "am": amo,
            "dq": "0",
            "pc": "NRML",
            "rt": "DAY",
            "pf": "N",
            "mp": "0",
            "pt": orderTypeValue,
            "es": exchangeSegment,
            "pr": price.toFixed(2),
            "qt": quantity,
            "ts": tsString,
            "tt": buySellValue
          });
        }

        quantity = nextQuantity;
      }
    }

    setFormDataArray(formDataArray);
    // console.log("Form Array:", formDataArray);

  };

  // Placing Order

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  
  const handleAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);

    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const placeOrder = () => {

    // Validate price fields
  if (orderType !== "MKT" && (isNaN(parseFloat(price)) || isNaN(parseFloat(priceDifference)) || isNaN(parseInt(orderStack)))) {
    alert("Please fill all price fields before placing an order.");
    return; // Stop execution if price fields are empty
  }

  generateFormData(); // Wait for generateFormData to finish
    // Order Place API Logic
    if (formDataArray.length > 0) {
      var qs = require('qs');
      for (let i = 0; i < formDataArray.length; i++) {
        var data = qs.stringify({
          'jData': JSON.stringify(formDataArray[i])
        });
        var config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://gw-napi.kotaksecurities.com/Orders/2.0/quick/order/rule/ms/place?sId=' + serverId + '',
          headers: {
            'accept': 'application/json',
            'Sid': userSId,
            'Auth': authToken,
            'neo-fin-key': 'neotradeapi',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': accessToken,
          },
          data: data
        };
  
        axios(config)
          .then(function (response) {
            // console.log(JSON.stringify(response.data));
            handleAlert('success', 'Orders Place Successfully.');
            // After order Place Get All Order Book Data
            orderBook();
          })
          .catch(function (error) {
            console.log(error);
            handleAlert('danger', 'Failed to orders place.');
          });
      }
    }
  }

  // Cancell Order Logic

  const [filteredData, setFilteredData] = useState([]);
  const [cancellOrders, setCancellOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(40); // Set Numbur of rows you want
  const [isLoading, setIsLoading] = useState(true);

 // Determine if there are cancellable orders
 const hasCancellableOrders = cancellOrders.length > 0;
 const cancelButtonText = hasCancellableOrders ? `Cancell Orders (${cancellOrders.length})` : 'Cancell Orders';

  // Get Order Book Data and run function when page load using useeffect
  const orderBook = () => {

    // Get Order Book Data
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://gw-napi.kotaksecurities.com/Orders/2.0/quick/user/orders?sId=' + serverId + '',
      headers: {
          'accept': 'application/json',
          'Sid': userSId,
          'Auth': authToken,
          'neo-fin-key': 'neotradeapi',
          'Authorization': accessToken
        }
      };
      axios(config).then(function (response) {
          // console.log('Order Book', JSON.stringify(response.data))
          const orderBook = response.data.data;
          if(orderBook.length > 0){
            const filteredData = orderBook.filter(data => (data.ordSt === orderSt ));
            // setFilteredData(filteredData); // Set the filtered data into state// after market order req received
            const cancellOrders = filteredData.map(data => ({ "on": data.nOrdNo, "am":amo }));
            setCancellOrders(cancellOrders); // Set the cancellorder on stat
            const groupedData = groupDataBySymbol(filteredData);
            const sortedData = sortGroupedData(groupedData);
            setFilteredData(sortedData);
            setIsLoading(false);
          }
          
          
      }).catch(function (error) {
          console.log(error);
          setIsLoading(false);
      });

  }

  // Sorting Data 
  const groupDataBySymbol = (data) => {
      return data.reduce((acc, item) => {
          if (!acc[item.sym]) {
              acc[item.sym] = [];
          }
          acc[item.sym].push(item);
          return acc;
      }, {});
  };

  const sortGroupedData = (groupedData) => {
      const sortedData = [];
      for (const symbol in groupedData) {
          if (groupedData.hasOwnProperty(symbol)) {
              const sortedGroup = groupedData[symbol].sort((a, b) => b.prc - a.prc);
              sortedData.push(...sortedGroup);
          }
      }
      return sortedData;
  };

  // Cancell All Orders Logic

  const handleCancelOrders = () => {

    if (!hasCancellableOrders) return; // Exit if no cancellable orders
    const confirmCancel = window.confirm(`Are you sure you want to cancel all ${cancellOrders.length} orders?`); // Display confirmation alert
    
    if (confirmCancel) {
      if(cancellOrders.length > 0){
          for (let i = 0; i < cancellOrders.length; i++) {
              var qs = require('qs');
              var data = qs.stringify({
              'jData': JSON.stringify(cancellOrders[i]) 
              });
              var config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: 'https://gw-napi.kotaksecurities.com/Orders/2.0/quick/order/cancel?sId=' + serverId + '',
              headers: { 
                  'accept': 'application/json', 
                  'Sid': userSId, 
                  'Auth': authToken,
                  'neo-fin-key': 'neotradeapi', 
                  'Content-Type': 'application/x-www-form-urlencoded', 
                  'Authorization': accessToken,
              },
              data : data
              };
              axios(config)
              .then(function (response) {
                  // console.log(JSON.stringify(response.data));
                  handleAlert('success', 'Orders cancelled successfully.');
                  orderBook();
              })
              .catch(function (error) {
                  console.log(error);
                  handleAlert('danger', 'Failed to cancel orders.');
              });
          } 
        }
      }
  }

  // Single Cancle Order 

  const handleCancelClick = (orderId) => {

    // Cancell Order API Logic

    var jsonData = {
        "on": orderId,
        "am" : amo
      };
    
    const cancellOrder = JSON.stringify(jsonData);
    console.log(cancellOrder);

    var qs = require('qs');
    var data = qs.stringify({
    'jData': cancellOrder 
    });
    var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://gw-napi.kotaksecurities.com/Orders/2.0/quick/order/cancel?sId=' + serverId + '',
    headers: { 
        'accept': 'application/json', 
        'Sid': userSId, 
        'Auth': authToken,
        'neo-fin-key': 'neotradeapi', 
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Authorization': accessToken,
    },
    data : data
    };

    axios(config)
    .then(function (response) {
        // console.log(JSON.stringify(response.data));
        orderBook();
    })
    .catch(function (error) {
    console.log(error);
    });

};

  // Modifying Order Logic

  const [showModal, setShowModal] = useState(false); // Step 1: State variable to manage modal visibility
  const [selectedOrderId, setSelectedOrderId] = useState(null); // State variable to store the selected order ID for modification
  
  const [updateQuantity, setUpdateQuantity] = useState(0); // New state for Quantity
  const [newPrice, setNewPrice] = useState(''); // New state for Price
   // Function to open the modal and set the selected order ID
  const openModal = (orderId) => {

    setSelectedOrderId(orderId);
    setShowModal(true);

    var jsonData = {
      "nOrdNo": orderId
    };
  
  const orderHistory = JSON.stringify(jsonData);
  console.log(orderHistory);

    var qs = require('qs');
    var data = qs.stringify({
      'jData': orderHistory 
     });
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://gw-napi.kotaksecurities.com/Orders/2.0/quick/order/history?sId=' + serverId + '',
      headers: { 
        'accept': 'application/json', 
        'Auth': authToken,
        'Sid': userSId, 
        'neo-fin-key': 'neotradeapi', 
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Authorization': accessToken
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      const orderData = response.data.data[0]
      setOrderHistory(orderData);
      setUpdateQuantity(orderData.qty);
      setNewPrice(orderData.prc);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    // You can also reset any necessary state variables here
  };

  const [orderHistory, setOrderHistory] = useState(null);

  const modifyOrder = () => {

    // Convert quantity and price to strings
  const quantityString = String(updateQuantity);
  const priceString = String(newPrice);

    // console.log('Order History',orderHistory);
    // console.log('Order History',orderHistory.tok);

    const orderUpdate = {
      "tk": orderHistory.tok,       // Token
      "mp": "0",           // Market Price
      "pc": "NRML",        // Product Code
      "dd": "NA",          // Delivery Date
      "dq": "0",           // Delivery Quantity
      "vd": "DAY",         // Validity
      "ts": orderHistory.trdSym,      // Trading Symbol
      "tt": orderHistory.trnsTp,      // Transaction Type
      "pr": priceString,                 // Price
      "tp": "0",                      // Trigger Price
      "qt": quantityString,           // Quantity
      "no": orderHistory.nOrdNo,      // Order Number
      "es": orderHistory.exSeg,       // Exchange Segment
      "pt": "L"                       // Price Type
    }

    const modifyOrder = JSON.stringify(orderUpdate);
    console.log(modifyOrder);

    var qs = require('qs');
    var data = qs.stringify({
      'jData': modifyOrder 
    });
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://gw-napi.kotaksecurities.com/Orders/2.0/quick/order/vr/modify?sId=' + serverId + '',
      headers: { 
        'accept': 'application/json', 
        'Auth': authToken,
        'Sid': userSId, 
        'neo-fin-key': 'neotradeapi', 
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Authorization': accessToken
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      orderBook();
    })
    .catch(function (error) {
      console.log(error);
    });
    closeModal();
  }

  // Modifying Order Logic End

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <header className="top-header">
          <Navigation /> {/* Render the Navigation component */}
      </header>
      <div className="container mt-4">
      {/* <h4>Order Form</h4> */}
      <div className="row">
        <div className="col-12 col-lg-3 col-md-6">
        <form id="optionsForm">
        <div className="mb-3">
          <select
            className="form-select"
            value={exchangeSegment}
            onChange={(e) => setExchangeSegment(e.target.value)}
          >
            <option value="nse_fo">NSE</option>
            <option value="bse_fo">BSE</option>
          </select>
        </div>
        <div className="mb-3">
          <select
            className="form-select"
            value={selectedSymbol}
            onChange={(e) => setSelectedSymbol(e.target.value)}
          >
            {Object.keys(symbolData[exchangeSegment]).map((symbol) => (
              <option key={symbol} value={symbol}>
                {symbol}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <select
            className="form-select"
            value={selectedStrikePrice}
            onChange={(e) => setSelectedStrikePrice(e.target.value)}
          >
            {symbolData[exchangeSegment][selectedSymbol]?.strikePrices.map((price) => (
              <option key={price} value={price}>
                {price}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <select
            className="form-select"
            id="optionType"
            value={optionType}
            onChange={(e) => setOptionType(e.target.value)}
          >
            <option value="CE">CE</option>
            <option value="PE">PE</option>
          </select>
        </div>
        <div className="mb-3">
          <select
            className="form-select"
            value={selectedExpiryDate}
            onChange={(e) => setSelectedExpiryDate(e.target.value)}
          >
            {symbolData[exchangeSegment][selectedSymbol]?.expiryDates.map((date) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <select
            className="form-select"
            value={orderType}
            onChange={(e) => setOrderType(e.target.value)}
          >
            <option value="L">LIMIT</option>
            <option value="MKT">MARKET</option>
          </select>
        </div>
        <div className="mb-3">
          <input type="text" placeholder="Enter Quantity" onChange={(e) => setDefaultQuantity(parseInt(e.target.value))} className="form-control" value={defaultQuantity} />
        </div>
        <div id="price-block" className="mb-3" style={{ display: togglePriceFieldVisibility() }}>
          <input
            type="text"
            placeholder="Enter Price"
            step="0.01"
            className="form-control"
            onChange={(e) => setPrice(parseFloat(e.target.value))}
          />
        </div>
        <div className="mb-3">
          <input
            type="number"
            placeholder="Price Difference"
            step="0.01"
            className="form-control"
            onChange={(e) => setPriceDifference(parseFloat(e.target.value))}
          />
        </div>
        <div className="mb-3">
          <input
            type="number"
            placeholder="Number of Stack"
            className="form-control"
            onChange={(e) => setOrderStack(parseInt(e.target.value))}
          />
        </div>
        <div className="mb-3">
          <select
            className="form-select"
            value={buySell}
            onChange={(e) => setBuySell(e.target.value)}
          >
            <option value="B">Buy</option>
            <option value="S">Sell</option>
          </select>
        </div>
      </form>
         <div className="mb-3 d-flex justify-content-between">
          <button type="button" className="btn btn-primary" onClick={placeOrder}>Place Order</button>
          <button className="btn btn-danger" onClick={handleCancelOrders} disabled={!hasCancellableOrders}>{cancelButtonText}</button>
        </div>
        </div>
        <div className="col-12 col-lg-9 col-md-6">
        {showAlert && (
          <div className={`alert alert-${alertType}`} role="alert">
            {alertMessage}
          </div>
        )} 
          {/*-- Order List --*/}
          {/* Order Book Table */}
          <div className='order-table'>
            {isLoading ? (
                    <div className='alert alert-success'> <strong>Loading...</strong></div>
                ) : filteredData.length === 0 ? (
                    <div className='alert alert-warning'> <strong>No data found.</strong></div>
                ) : (
                  <div className='table-responsive'>
                <table className="table table-bordered">
                    <thead>
                        <tr className="text-center">
                            <th>Symbol</th>
                            {/* <th>Strike</th> */}
                            <th>Trade Symbol</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Type</th>
                            <th>Order Status</th>
                            {/* <th>Order No.</th> */}
                            {/* <th>Exchange Segment</th> */}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {currentRows.map(data => (
                            <tr key={data.id}>
                                <td>{data.sym}</td>
                                <td>{parseInt(data.stkPrc)} {data.optTp}</td>
                                {/* <td>{data.trdSym}</td> */}
                                <td>{data.prc}</td>
                                <td>{parseInt(data.qty)}</td>
                                {/* <td>{data.trnsTp}</td> */}
                                <td><span className={`p-2 badge bg-${data.trnsTp === 'B' ? 'success' : 'danger'}`} >
                                    {data.trnsTp === 'B' ? 'BUY' : 'SELL'}
                                </span></td>
                                <td>{data.ordSt}</td>
                                {/* <td>{data.nOrdNo}</td> */}
                                {/* <td>{data.exSeg}</td> */}
                                <td>
                                    <button class="btn btn-danger btn-sm me-1" value={data.nOrdNo} onClick={() => handleCancelClick(data.nOrdNo)}><FontAwesomeIcon icon={faTrash} /></button>
                                    <button class="btn btn-primary btn-sm" value={data.nOrdNo} onClick={() => openModal(data.nOrdNo)}><FontAwesomeIcon icon={faEdit} /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                )}
          </div>
          {/* <div className='text-end all-cancell'>
            <button className="btn btn-danger my-4" onClick={handleCancelOrders} disabled={!hasCancellableOrders}>{cancelButtonText}</button>
          </div> */}
          {filteredData.length > 0 && (
          <div className='row'>
              <div className='col-12'>
                  <Pagination className="justify-content-end py-3">
                      {Array.from({ length: Math.ceil(filteredData.length / rowsPerPage) }, (_, index) => (
                          <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
                              {index + 1}
                          </Pagination.Item>
                      ))}
                  </Pagination>
              </div>
          </div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          {/* Modal for modifying orders */}
          <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Modify Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formQuantity">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control type="number" value={updateQuantity} onChange={(e) => setUpdateQuantity(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="formPrice">
                  <Form.Label>Price</Form.Label>
                  <Form.Control type="text" value={newPrice} onChange={(e) => setNewPrice(e.target.value)} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>Close</Button>
              <Button variant="primary" onClick={modifyOrder}>Save Changes</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
    {/* <footer>
      <Footer />
    </footer> */}
    </div>
    
  );
};

export default Orders;
