// Navigation.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';


const Navigation = () => {

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');  
  };

  useEffect(() => {
    const SID = localStorage.getItem('userSid');
    const jwtToken = localStorage.getItem('jwtToken');

    // Check if SID and jwtToken are not found
    if (!SID || !jwtToken) {
      // Redirect to the login page
      navigate('/login'); // Navigate to the login page
    }
  }, [navigate]);

  useEffect(() => {
    const clearLocalStorageAtMidnight = () => {
      // Get current time
      const currentTime = new Date();

      // Check if it's midnight (00:00:00)
      if (currentTime.getHours() === 6 && currentTime.getMinutes() === 0 && currentTime.getSeconds() === 0) {
        // Clear local storage
        localStorage.clear();
      }
    };

    // Run the function every second to check for midnight
    const intervalId = setInterval(clearLocalStorageAtMidnight, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Navigation Clock 

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  // const amPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light py-3">
      <div className="container">
        <a className="navbar-brand logo" href="/dashboard"><h4 className='m-0 text-danger'><span className='text-white text-center bg-dark me-1 ps-1'>MK </span> Traders</h4></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/dashboard" className="nav-link">Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/orders" className="nav-link">Orders</Link>
            </li>
            <li className="nav-item">
              <Link to="/investment" className="nav-link">Investment</Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/master-scripts" className="nav-link">Scripts</Link>
            </li> */}
            <li className="nav-item">
              <Link to="/positions" className="nav-link">Positions</Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/portfolio" className="nav-link">Portfolio</Link>
            </li> */}
            {/* <li className="nav-item">
              <Link to="/live-data" className="nav-link">Live Data</Link>
            </li> */}
            <li className="nav-item">
              <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
            </li>
            {/* <li className="nav-item text-center bg-success text-white time-clock">
              <span className="">
              <span className="clock">{formattedHours < 10 ? '0' + formattedHours : formattedHours}</span>: 
                <span className="clock">{minutes < 10 ? '0' + minutes : minutes}</span>:  
                <span className="clock">{seconds < 10 ? '0' + seconds : seconds}</span>
                <span className="clock">{amPm}</span>
              </span>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
