import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navigation from './Navigation';
import Papa from 'papaparse';

const Dashboard = () => {
    const userName = localStorage.getItem('userName');
    const accessToken = localStorage.getItem('accessToken');
    const [filePaths, setFilePaths] = useState([]);
    const [selectedFilePath, setSelectedFilePath] = useState(null);
    const [uniqueInstTypes, setUniqueInstTypes] = useState([]);
    const [selectedInstType, setSelectedInstType] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [uniqueSymbolNames, setUniqueSymbolNames] = useState([]);
    const [selectedSymbolName, setSelectedSymbolName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        handleScriptsFile();
    }, []);

    const handleScriptsFile = () => {
        axios.get('https://gw-napi.kotaksecurities.com/Files/1.0/masterscrip/v1/file-paths', {
            headers: {
                'accept': '*/*',
                'Authorization': accessToken,
            }
        })
        .then(function (response) {
            const filteredPaths = response.data.data.filesPaths.filter(filePath =>
                filePath.includes('nse_fo.csv') || filePath.includes('bse_fo.csv')
            );
            setFilePaths(filteredPaths);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const fetchDataAndParse = (filePath) => {
        axios.get(filePath)
        .then(response => {
            const parsedData = Papa.parse(response.data, { header: true }).data;
            setCsvData(parsedData);
            const instTypes = [...new Set(parsedData.map(row => row.pInstType))];
            setUniqueInstTypes(instTypes);
        })
        .catch(error => {
            console.error('Error fetching CSV data:', error);
        });
    }

    useEffect(() => {
        if (selectedFilePath) {
            fetchDataAndParse(selectedFilePath);
        }
    }, [selectedFilePath]);

    useEffect(() => {
        if (selectedInstType) {
            const filteredSymbolNames = [...new Set(csvData.filter(row => row.pInstType === selectedInstType).map(row => row.pSymbolName))];
            setUniqueSymbolNames(filteredSymbolNames);
        }
    }, [selectedInstType, csvData]);
    
    // Filtering Table Data
    useEffect(() => {
        if (selectedSymbolName) {
            const filteredData = csvData.filter(row => row.pSymbolName === selectedSymbolName);
            setCsvData(filteredData);
        }
    }, [selectedSymbolName]);

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = csvData.slice(indexOfFirstRow, indexOfLastRow);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div>
            <header className="top-header">
                <Navigation />
            </header>
            <div className="container py-4">
                <div className="row">
                    <div className="col-md-12">
                        <h4>CSV Master Scripts File</h4>
                        <select className="form-select mt-3" onChange={(e) => setSelectedFilePath(e.target.value)}>
                            <option value="">Select CSV file</option>
                            {filePaths.map((filePath, index) => (
                                <option key={index} value={filePath}>{filePath.includes('nse_fo.csv') ? 'NSE F&O' : filePath.includes('bse_fo.csv') ? 'BSE F&O' : " "}</option>
                            ))}
                        </select>
                        {selectedFilePath &&
                            <div>
                                <h6 className="mt-4">Select Inst Type</h6>
                                <select className="form-select mt-3" onChange={(e) => setSelectedInstType(e.target.value)}>
                                    <option value="">Select Inst Type</option>
                                    {uniqueInstTypes.map((instType, index) => (
                                        <option key={index} value={instType}>{instType}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        {selectedInstType &&
                            <div>
                                <h6 className="mt-4">Select Symbol Name</h6>
                                <select className="form-select mt-3" onChange={(e) => setSelectedSymbolName(e.target.value)}>
                                    <option value="">Select Symbol Name</option>
                                    {uniqueSymbolNames.map((symbolName, index) => (
                                        <option key={index} value={symbolName}>{symbolName}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        {selectedSymbolName &&
                            <div className="mt-4">
                                <h5>Filtered Data</h5>
                                <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {Object.keys(currentRows[0]).map((key, index) => (
                                                <th key={index}>{key}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentRows.map((row, index) => (
                                            <tr key={index}>
                                                {Object.values(row).map((value, index) => (
                                                    <td key={index}>{value}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <nav>
                                    <ul className="pagination">
                                        {Array.from({ length: Math.ceil(csvData.length / rowsPerPage) }, (_, index) => (
                                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(index + 1)}>
                                                    {index + 1}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
