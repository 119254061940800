import React, { useState, useEffect } from 'react';
import Navigation from './Navigation'; // Import the Navigation component
import moment from 'moment';

const GoogleSheetData = () => {

  const readGoogleSheet = () => {
  fetch('https://sheetdb.io/api/v1/t18q7h8nlp836')
  .then((response) => response.json())
  .then((data) => console.log(data));
  }
  
  const addDataGoogleSheet = () => {
    const currentDate = moment().format('YYYY-MM-DD');

    fetch('https://sheetdb.io/api/v1/t18q7h8nlp836')
    .then((response) => response.json())
    .then((data) => {
      // Check if any entry has today's date
      const todayData = data.find(entry => moment(entry.Date).format('YYYY-MM-DD') === currentDate);
      console.log(todayData);
      
      if (todayData) {
        console.log('Data for today exists:', todayData);
      } else {
        console.log('No data found for today.');
        fetch('https://sheetdb.io/api/v1/t18q7h8nlp836', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          data: [
              {
                  'Date': currentDate,
                  'PNL': "3430",
              }
          ]
      })
     })
      .then((response) => response.json())
      .then((data) => console.log(data));
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    }); 
  }

  return (
    <div className="container">
      <header className="top-header">
          <Navigation /> {/* Render the Navigation component */}
      </header>
      <div className="row">
        <div className="col-12">
          <h4>Subscribing to HSM demo</h4>
          <p>You can use this file to get Live Broadcast of a scrip, Pause, Unpause, Resume etc</p>
          <button className='btn btn-primary me-3' onClick={()=>readGoogleSheet()}>Read Data</button>
          <button className='btn btn-success' onClick={()=>addDataGoogleSheet()}>Add Data</button>
          <div>
      </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleSheetData;

