// Footer.js
import React, { useState, useEffect } from 'react';

const Footer = () => {

  // Navigation Clock 

//   const [time, setTime] = useState(new Date());

//   useEffect(() => {
//     const timerID = setInterval(() => {
//       setTime(new Date());
//     }, 1000);

//     return () => {
//       clearInterval(timerID);
//     };
//   }, []);

//   const hours = time.getHours();
//   const minutes = time.getMinutes();
//   const seconds = time.getSeconds();
//   const amPm = hours >= 12 ? 'PM' : 'AM';
//   const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight

  return (
    <div>
    <div className="bg-light fixed-bottom w-100">
      <div className="container py-4">
        <div className='row'>
            <div className='col-12'>
                <div className='d-flex justify-content-center'>
                <div className='copyright'>
                <p className='m-0 h6'>&copy; Copyright 2024 MK Traders . All Rights Reserved.</p>
                </div>
                {/* <div className="text-center bg-success text-white time-clock px-4">
                    <span className="">
                    <span className="clock">{formattedHours < 10 ? '0' + formattedHours : formattedHours}</span>: 
                        <span className="clock">{minutes < 10 ? '0' + minutes : minutes}</span>:  
                        <span className="clock">{seconds < 10 ? '0' + seconds : seconds}</span>
                        <span className="clock"> {amPm}</span>
                    </span> 
                </div> */}
                </div>
                
            </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Footer;
