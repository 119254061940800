import React, { useState, useEffect } from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
    const location = useLocation();
    const { responseData } = location.state || {};
    const otpData = responseData ? responseData.data : null;
    const jwtTokenNew = otpData ? otpData.token : null;

    useEffect(() => {
        if (jwtTokenNew) {
            localStorage.setItem('jwtToken', jwtTokenNew);
        }
    }, [jwtTokenNew]);

    const userName = localStorage.getItem('userName') || 'Guest'; // Default to 'Guest' if userName is not set

    return (
        <div>
            <header className="top-header">
                <Navigation />
            </header>
            <div className="container py-4">
                <div className="row">
                    <div className="col-md-12">
                        <h4>Welcome, {userName}!</h4>
                    </div>
                    <div className='col-12 py-3'>
                    <ul class="list-group">
                        <li class="list-group-item"><strong>Risk Management:</strong><br/>Only risk a small percentage of your trading capital on any single trade, typically no more than 1-2%. Use stop-loss orders to limit potential losses and protect your capital.</li>
                        <li class="list-group-item"><strong>Trade with a Plan:</strong><br/> Develop a clear trading plan with predefined entry and exit points based on thorough analysis. Stick to your plan and avoid impulsive decisions driven by emotions.</li>
                        <li class="list-group-item"><strong>Diversification:</strong><br/> Spread your investments across different asset classes, sectors, and geographic regions to reduce risk. Avoid putting all your capital into a single investment.</li>
                        <li class="list-group-item"><strong>Continuous Learning:</strong><br/> Stay informed about market trends, economic indicators, and news that may impact your investments. Regularly review and analyze your trading performance to identify areas for improvement.</li>
                        <li class="list-group-item"><strong>Patience and Discipline:</strong><br/> Be patient and wait for high-probability trading opportunities that align with your strategy. Exercise discipline by following your trading plan rigorously and avoiding overtrading.</li>
                    </ul>
                    </div>
                </div>
            </div>
            <footer>
                <Footer />
            </footer>
        </div>
    );
};

export default Dashboard;
