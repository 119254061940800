import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AccessToken from './components/AccessToken';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import PlaceOrder from './components/Orders';
import Investment from './components/Investment'; // Corrected import
import Positions from './components/Positions';
import MasterScripts from './components/MasterScripts';
import Portfolio from './components/Portfolio';
import WebSocket from './components/WebSocket';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';


function App() {
  const isLoggedIn = !!localStorage.getItem('token');

  const PrivateRoute = ({ element: Element, ...rest }) => (
    <Route {...rest} element={isLoggedIn ? <Element /> : <Navigate to="/" />} />
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AccessToken />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/orders" element={<PlaceOrder />} />
        <Route path="/investment" element={<Investment />} />
        <Route path="/master-scripts" element={<MasterScripts />} />
        <Route path="/positions" element={<Positions />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/live-data" element={<WebSocket />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
